<template>
  <v-card class="pa-2">
    <v-form ref="form" @submit.prevent="save">
      <v-row>
        <v-col cols="12">
          <v-select
            filled
            hide-details="auto"
            v-model="modificacion.familia"
            :items="familias"
            :item-text="item => item.title"
            :item-value="item => item.val"
            label="Familia"
            :rules="[rules.req]"
            class="v-superdense-input"
            append-icon="mdi-graph"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="modificacion.nombre"
            label="Nombre"
            :rules="[rules.req]"
            class="v-superdense-input"
            append-icon="mdi-rename"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="modificacion.add"
            label="Añadir (ej: 7.20)"
            append-icon="mdi-currency-eur"
            :rules="[rules.isNumber]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="modificacion.remove"
            label="Quitar (ej: -5.50)"
            append-icon="mdi-currency-eur"
            :rules="[rules.isNumber]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            filled
            hide-details="auto"
            v-model="modificacion.replace"
            label="Substituir (ej: 2.30)"
            append-icon="mdi-currency-eur"
            :rules="[rules.isNumber]"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-card-actions class="d-flex">
						<v-spacer></v-spacer>
						<v-btn v-if="modificacion.idModificacion" @click.stop="modificacion = {}">
							<v-icon left>mdi-close</v-icon>
                Cancelar
						</v-btn>
						<v-btn @click.stop="save" color="primary">
							<v-icon left>mdi-content-save</v-icon>
							{{ modificacion.idModificacion ? "Actualizar" : "Crear" }}
						</v-btn>
					</v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { req, isNumber } from "@/utils/validations.js";

export default {
  props: {
    familias: Array,
    value: Object,
  },
  data() {
    return {
      default: {
        nombre : null,
      },
      modificacion: {
        ...this.value,
      },
      rules: {
        req,
        isNumber,
      },
    };
  },
  methods: {
    async save() {
      if (!this.modificacion.nombre || this.modificacion.nombre.length == 0) this.modificacion.nombre = this.default.nombre;
      if (!this.$refs.form.validate()) return;
      this.modificacion.add = parseFloat(this.modificacion.add || 0).toFixed(2).replaceAll(',','.')
      this.modificacion.remove = parseFloat(this.modificacion.remove || 0).toFixed(2).replaceAll(',','.')
      this.modificacion.remove = Math.abs(this.modificacion.remove)*-1
      this.modificacion.replace = parseFloat(this.modificacion.replace || 0).toFixed(2).replaceAll(',','.')
      await axios({
        method: this.modificacion.idModificacion ? "PUT" : "POST",
        url: `/modificaciones/${String(
          this.modificacion.idModificacion ? this.modificacion.idModificacion : ""
        )}`.replace(/\/+$/, ''),
        data: {
          ...this.modificacion,
        },
      });
      this.modificacion = {}
      this.$emit("reload");
    },
  },
};
</script>